// document.addEventListener("DOMContentLoaded", function() {
//   const container = document.getElementById("container-list-grid");
//
//   if (container !== null) {
//     document.querySelector('.grid-list').addEventListener('click', function() {
//       this.classList.add('animation');
//       this.classList.toggle('active');
//
//       let newElem = this.cloneNode(true);
//       this.parentNode.replaceChild(newElem, this);
//       newElem.addEventListener('click', function() {
//         newElem.classList.add('animation');
//         newElem.classList.toggle('active');
//
//         if (newElem.classList.contains('active')) {
//           // Affichage en liste
//           container.classList.add("list-container-product");
//           adjustDisplay("block", "add");
//         } else {
//           // Affichage en grille
//           container.classList.remove("list-container-product");
//           adjustDisplay("none", "remove");
//         }
//       });
//
//       if (this.classList.contains('active')) {
//         // Affichage en liste
//         container.classList.add("list-container-product");
//         adjustDisplay("block", "add");
//       } else {
//         // Affichage en grille
//         container.classList.remove("list-container-product");
//         adjustDisplay("none", "remove");
//       }
//     });
//   }
//
//   function adjustDisplay(displayValue, action) {
//     let refs = document.querySelectorAll(".card-product-ref");
//     let descs = document.querySelectorAll(".card-product-description");
//     let grids = document.querySelectorAll(".card-product-grid");
//     let cardInfos = document.querySelectorAll(".card-infos");
//     let cardImgs = document.querySelectorAll(".card-img-grid");
//     let footers = document.querySelectorAll(".footer-card-product");
//
//     refs.forEach(ref => ref.style.display = displayValue);
//     descs.forEach(desc => desc.style.display = displayValue);
//
//     if (action === "add") {
//       grids.forEach(grid => grid.classList.add("card-product-list"));
//       cardInfos.forEach(info => info.classList.add("class-list-product1"));
//       cardImgs.forEach(img => img.classList.add("class-list-product2"));
//       footers.forEach(footer => footer.classList.add("class-list-product3"));
//     } else {
//       grids.forEach(grid => grid.classList.remove("card-product-list"));
//       cardInfos.forEach(info => info.classList.remove("class-list-product1"));
//       cardImgs.forEach(img => img.classList.remove("class-list-product2"));
//       footers.forEach(footer => footer.classList.remove("class-list-product3"));
//     }
//   }
// });
